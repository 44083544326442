import Vue from 'vue'
import Vuex from 'vuex'
import httpClient from '@/plugins/axios'

Vue.use(Vuex)
const defaultState = {
  faqs: {
    categories: [],
    categoryCodes: [],
    faqs: {},
    locale: null
  },
  awards: []
}

function saveState (state) {
  sessionStorage.setItem('qic_static', JSON.stringify(state))
}

function savedState () {
  let result = {}

  try {
    result = JSON.parse(sessionStorage.getItem('qic_static'))
  } catch { }

  return result
}

export default new Vuex.Store({
  state () {
    const result = {
      ...defaultState,
      ...savedState()
    }
    return result
  },
  getters: {
    savedLocale () {
      return localStorage.getItem('savedLocale') || null
    }
  },
  mutations: {
    saveLocale (locale) {
      localStorage.setItem('savedLocale', locale)
    },
    setFaqs (state, faqs) {
      state.faqs = faqs
      saveState(state)
    },
    setAwards (state, list) {
      state.awards = list
      saveState(state)
    }
  },
  actions: {
    getAwards ({ commit }, page) {
      return httpClient.get(`/getawardslist?Page=${page}`)
        .then((res) => {
          commit('setAwards', res.data.list)
        })
    },
    sendCallback (_, data) {
      const formData = Object.entries(data).reduce((d, [key, value]) => {
        return `${d ? d + '&' : ''}${key}=${value}`
      }, '')

      return httpClient.post('sendcallback', formData)
        .then(() => true)
    },
    sendFeedback (_, data) {
      const formData = Object.entries(data).reduce((d, [key, value]) => {
        return `${d ? d + '&' : ''}${key}=${value}`
      }, '')

      return httpClient.post('sendfeedback', formData)
        .then(() => true)
    },
    sendComplaint (_, data) {
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value)
        }
      }, '')

      return httpClient.post('/sendComplaint', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
    },
    getFaqList ({ commit, state }, locale) {
      if (state.faqs.faqs.length && state.faqs.locale === locale) return state.faqs
      return httpClient.get('/getfaqs')
        .then((res) => {
          const isArabic = (locale || 'en') === 'ar'

          const faqs = res.data.FAQs
          if (!faqs || !faqs?.length) return new Error('FAQs error')

          const categories = [...faqs.reduce((acc, c) => {
            acc.add(c.Category)
            return acc
          }, new Set())]
          // const categories = ['General points', 'Car', 'Motorcycle', 'Boat & Yacht', 'Home', 'Travel', 'Personal Accident', 'Claim']
          const categoryCodes = ['general', 'car', 'travel', 'moto', 'home', 'boat', 'pab', 'claims']
          const result = {
            categories,
            categoryCodes,
            faqs: {},
            locale
          }
          faqs.forEach(f => {
            if ((categories.indexOf(f.Category) < 0) || !!f.IsArabic !== !!isArabic) return
            const code = categoryCodes[categories.indexOf(f.Category)]
            const faq = {
              question: f.Question,
              answer: f.Answer,
              isArabic: f.IsArabic,
              id: f.ID,
              sortOrder: f.SortOrder
            }
            if (!result.faqs[code]) {
              result.faqs[code] = {
                list: [faq],
                title: f.Category
              }
            } else {
              result.faqs[code].list.push(faq)
            }
          })
          commit('setFaqs', result)
          return result
        })
    },
    getBranchList () {
      return httpClient.get('/getqicoffices')
        .then((res) => {
          return res.data.offices.map((office) => {
            return {
              id: office.ID,
              name: office.Name,
              address: office.Address,
              poBoxo: office.POBox,
              street: office.Street,
              state: office.State,
              country: office.Country,
              phone: office.Phone,
              email: office.Email,
              schedule: office.Schedule,
              ramadanSchedule: office.RamadanSchedule,
              lat: office.Lat,
              lng: office.Lng,
              isArabic: office.IsArabic,
              sortOrder: office.SortOrder,
              titleAddSchedule: office.TitleAddSchedule,
              mapLink: office.MapLink
            }
          })
        })
    },
    getComplaintTypeList () {
      return httpClient.get('/getlists/complainttypes')
        .then((res) => {
          return res.data.list.map((complaintType, index) => {
            return {
              id: index + 1,
              code: complaintType.code,
              name: complaintType.name
            }
          })
        })
    }
  },
  modules: {
  }
})
