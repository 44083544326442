import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import FeedbackPage from '@/views/FeedbackPage.vue'
import ComplaintPage from '@/views/ComplaintPage.vue'
import WordingsPage from '@/views/WordingsPage.vue'
import Sitemap from '@/views/Sitemap.vue'
import Installment from '@/views/Installment.vue'
import Contacts from '@/views/Contacts.vue'
import MainFaqPage from '@/views/MainFaqPage.vue'
import FaqSearch from '@/views/FaqSearch.vue'
import FaqCategory from '@/views/FaqCategory.vue'
import BusinessInsurance from '@/views/BusinessInsurance.vue'
import ThankYouPage from '@/views/ThankYouPage.vue'
import AboutPage from '@/views/About.vue'
import HistoryPage from '@/views/History.vue'
import Download from '@/views/Download.vue'

Vue.use(VueRouter)

const lang = process.env.VUE_APP_I18N_LOCALE
const path = process.env[`VUE_APP_${lang.toUpperCase()}_BASE_URL`] || process.env.VUE_APP_PUBLIC_PATH
// console.log(process.env.BASE_URL)
const routes = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: '/feedback',
    name: 'FeedbackPage',
    component: FeedbackPage
  },
  {
    path: '/complaint',
    name: 'ComplaintPage',
    component: ComplaintPage
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: Sitemap
  },
  {
    path: '/installment',
    name: 'Installment',
    component: Installment
  },
  {
    path: '/wordings',
    name: 'WordingsPage',
    component: WordingsPage
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/faq/',
    name: 'MainFaqPage',
    component: MainFaqPage
  },
  {
    path: '/faq/search-results',
    name: 'FaqSearch',
    component: FaqSearch
  },
  {
    path: '/faq/categories',
    name: 'FaqCategory',
    component: FaqCategory,
    props: true
  },
  {
    path: '/business-insurance',
    name: 'BusinessInsurance',
    component: BusinessInsurance
  },
  {
    path: '/thank-you',
    name: 'ThankYouPage',
    component: ThankYouPage
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/history',
    name: 'HistoryPage',
    component: HistoryPage
  },
  {
    path: '/app/download',
    name: 'Download',
    component: Download
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: path,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  function getUtmQueryParams () {
    const toQuery = to.query
    const fromQueryUtm = Object.entries(from.query).filter(([key]) => key.startsWith('utm_') && !toQuery[key])
    return fromQueryUtm.length ? { ...toQuery, ...Object.fromEntries(fromQueryUtm) } : null
  }

  const utmParams = getUtmQueryParams()
  if (utmParams) next({ name: to.name, query: utmParams })
  else next()
})

router.afterEach((_, from) => {
  if (process.env.NODE_ENV === 'development') return

  let referrer = null

  if (from !== VueRouter.START_LOCATION) {
    referrer = `${location.origin}${router.options.base.slice(0, -1)}${from.fullPath}`
  }

  notifyApi(referrer)
})

let clientId = null

function notifyApi (referrer) {
  if (!clientId) {
    if (!window.gtag) setTimeout(notifyApi, 100)
    else {
      window.gtag('get', 'G-Q56TLZLG64', 'client_id', (cid) => {
        clientId = cid
        notifyApi(referrer)
      })
    }
  } else {
    const url = new URL(window.location.href)
    const data = {
      event_name: 'view_page',
      path: window.location.pathname,
      url: window.location.href,
      referrer: referrer || document.referrer,
      google_id: clientId,
      timestamp: Math.floor(Date.now() / 1000),
      utm_source: url.searchParams.get('utm_source') || '',
      utm_medium: url.searchParams.get('utm_medium') || '',
      utm_campaign: url.searchParams.get('utm_campaign') || '',
      utm_term: url.searchParams.get('utm_term') || '',
      utm_content: url.searchParams.get('utm_content') || ''
    }
    const xhr = new XMLHttpRequest()
    xhr.open('POST', '/api/v1/events/?action=pushevent')
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send(new URLSearchParams(data).toString())
  }
}
export default router
